<template>
  <div class="page-container account-form-page">
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Conta do paciente</h1>
      </div>
      <div class="card-body">
        <account-data :id="id" />
      </div>
      <div class="card-footer">
      </div>
    </div>
  </div>
</template>

<script>
import accountData from './Data.vue';

export default {
  components: {
    accountData,
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";
.account-form-page {}
</style>
